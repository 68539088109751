<template>
  <router-link
    :to="`/products/collection/${$helpers.format.slug(`${collection.name} ${collection.id}`)}`"
    class="px-1 text-black d-flex flex-column h-100"
  >
    <h5 class="font-14px roboto-medium m-0">
      {{ collection.name }}
    </h5>
    <small class="collection-desc">{{ collection.description }}</small>
    <small class="roboto-medium mt-auto">
      Shop the collection <font-awesome icon="chevron-right" />
    </small>
    <picture class="mb-3">
      <source media="(min-width: 768px)" :srcset="collection.image" :alt="`${collection.brand_name} ${collection.name}`" fluid-grow>
      <b-img :src="collection.image_mobile" :alt="`${collection.brand_name} ${collection.name}`" fluid-grow></b-img>
    </picture>
  </router-link>
</template>

<script>
export default {
  name: "ProductCollectionCard",
  props: ["collection"],

}
</script>
<style scoped>
.collection-desc {
  white-space: pre-line;
  line-height: 19px;
}
</style>